import {VierdaagseResult} from "../types/VierdaagseApi";
import {matchArtists} from "./matchArtists";
import {locationIdToLocation} from "./helper";

export async function getVierdaagseAPI(){
    return fetch('/vierdaagse').then((response) => {
        return response.json().then((data: VierdaagseResult) => {
            const date_to_day_id = (date: Date) => {
                // Format the date as YYYY-MM-DD to match the format in day objects
                const dateString = date.toISOString().split('T')[0];

                // Find the matching day object
                const matchingDay = data.days.find(day => {
                    // Extract just the date part (YYYY-MM-DD) from the ISO string
                    const dayDate = day.date.split('T')[0];
                    return dayDate === dateString;
                });

                // Return the ID if found, otherwise null
                return matchingDay ? matchingDay.id : null;
            };
            const newPrograms = []
            for(let act of data.programs) {
                if(!act.location){
                    act.location = {
                            id: 0,
                            title: "Unknown",
                            description_short: 'Unknown',
                            description: "Unknown",
                            marker: {
                                lat: 0,
                                lng: 0
                            },
                            logo: "",
                            images: [],
                            url: "",
                            slug: "",
                            type: ""
                    }
                }else{
                    act.location = locationIdToLocation(act.location.id, data.locations);
                }
                if(!act.day){
                    const date = new Date(
                        parseInt(act.sortDate.substring(0, 4)),
                        parseInt(act.sortDate.substring(4, 6)) - 1,
                        parseInt(act.sortDate.substring(6, 8)),
                        parseInt(act.sortDate.substring(8, 10)),
                        parseInt(act.sortDate.substring(10, 12))
                    );

                    // Get the day ID from the date
                    const dayId = date_to_day_id(date);

                    // Assign the day ID to the act
                    if (dayId) {
                        act.day = { id: dayId, date: date.toISOString() };
                    }
                }
                newPrograms.push(act);
            }
            data.programs = newPrograms;
            return data;
        }).catch((error) => {
            console.log(error);
            return null;
        });
    }).catch((error) => {
        console.log(error);
        return null;
    });
}
